
import { useTranslation } from "react-i18next";

interface Props {
    message:string,
    links?:Link[]
}

export interface Link {
    label:string,
    url:string
}


export default function ErrorScreen({message, links}:Props){
    const {t} = useTranslation()

    return (<div className="main-container wrap">
        <h1>{t("UI.ERROR.TITLE")}</h1>
        <p>{message}</p>
        <ul className="links" style={{marginTop:"2rem"}}>
        {links?.map((link,index) => 
            <li key={index}>
                <a href={link.url}>{link.label}</a>
            </li>)  }
        </ul>
        
    </div>);
}