import {IccCryptoXApi} from "@icure/api/icc-x-api/icc-crypto-x-api";
import {
  IccAccesslogApi,
  IccAgendaApi,
  IccAuthApi,
  IccCalendarItemApi,
  IccContactApi,
  IccEntityrefApi,
  IccGroupApi,
  IccInsuranceApi,
  IccPatientApi,
} from "@icure/api/icc-api";
import {IccUserXApi} from "@icure/api/icc-x-api/icc-user-x-api";
import {IccPatientXApi} from "@icure/api/icc-x-api/icc-patient-x-api";
import {IccHcpartyXApi} from "@icure/api/icc-x-api/icc-hcparty-x-api";
import {IccDeviceApi} from "@icure/api/icc-api/api/IccDeviceApi";
import {IccAccesslogXApi} from "@icure/api/icc-x-api/icc-accesslog-x-api";
import {IccContactXApi} from "@icure/api/icc-x-api/icc-contact-x-api";
import {IccHelementXApi} from "@icure/api/icc-x-api/icc-helement-x-api";
import {IccDocumentXApi} from "@icure/api/icc-x-api/icc-document-x-api";
import {IccFormXApi} from "@icure/api/icc-x-api/icc-form-x-api";
import {IccInvoiceXApi} from "@icure/api/icc-x-api/icc-invoice-x-api";
import {IccMessageXApi} from "@icure/api/icc-x-api/icc-message-x-api";
import {IccReceiptXApi} from "@icure/api/icc-x-api/icc-receipt-x-api";
import {IccCalendarItemXApi} from "@icure/api/icc-x-api/icc-calendar-item-x-api";
import {IccClassificationXApi} from "@icure/api/icc-x-api/icc-classification-x-api";
import {IccTimeTableXApi} from "@icure/api/icc-x-api/icc-time-table-x-api";
import {IccMaintenanceTaskXApi} from "@icure/api/icc-x-api/icc-maintenance-task-x-api";

export  enum ClassNames {
    submitted= 'submitted',
    validated = 'validated',
    flowContent = 'flow-content'
}

export enum ERROR {
    ID_CODE_INVALID = "ID_CODE_INVALID",
    ID_CODE_WRONG = "ID_CODE_WRONG",
    ID_SERVER = "ID_SERVER",
    PATIENT_CONFIGURATION = "PATIENT_CONFIGURATION",
    SAVE_APPOINTMENT = "SAVE_APPOINTMENT",
    SLOT_NOT_AVAILABLE = "SLOT_NOT_AVAILABLE",
    RECAPTCHA = "RECAPTCHA",
    IS_MEDISPRING_USER ="IS_MEDISPRING_USER",
    DELETED_PATIENT = "DELETED_PATIENT"
}


export interface TokenByGroup {
    groupId:string,
    token:string,
}

export interface Patient {
    firstName?:string,
    lastName?:string,
    phoneNumber?:string,
    email?:string,
    visitedHealthcarePartyIds:string[],
    tokensByGroup:TokenByGroup[],
    userId?:string,
}

export enum ScreenName {
    PLACE_CHOICE = "PLACE_CHOICE",
    NEW_PATIENT_QUESTION = "NEW_PATIENT_QUESTION",
    NEW_PATIENT_REJECTION = "NEW_PATIENT_REJECTION",
    CALENDAR_ITEM_TYPE_CHOICE = "CALENDAR_ITEM_TYPE_CHOICE",
    TIMESLOT_CHOICE =  "TIMESLOT_CHOICE",
    IDENTIFICATION = "IDENTIFICATION",
    VALIDATION = "VALIDATION",
    SAVE_APPOINTMENT = "SAVE_APPOINTMENT",
    END = "END",
}

export interface MailData {
    ["g-recaptcha-response"]: string,
    from:string,
    hcp:string,
    firstName:string,
    lastName:string,
    emailAddress:string,
    mobilePhone:string,
    group:string,
    hcpId:string,
}

export enum InputName {
    LASTNAME = "LASTNAME",
    FIRSTNAME = "FIRSTNAME",
    PHONE = "PHONE",
    EMAIL = "EMAIL",
    REMEMBER_ME = "REMEMBER_ME",
    REASON = "REASON"
}

export interface IcureApi {
  maintenanceTaskApi: IccMaintenanceTaskXApi;
  cryptoApi: IccCryptoXApi;
  authApi: IccAuthApi;
  userApi: IccUserXApi;
  patientApi: IccPatientXApi;
  healthcarePartyApi: IccHcpartyXApi;
  deviceApi: IccDeviceApi;
  accessLogApi: IccAccesslogXApi;
  contactApi: IccContactXApi;
  healthcareElementApi: IccHelementXApi;
  documentApi: IccDocumentXApi;
  formApi: IccFormXApi;
  invoiceApi: IccInvoiceXApi;
  insuranceApi: IccInsuranceApi;
  messageApi: IccMessageXApi;
  entityReferenceApi: IccEntityrefApi;
  receiptApi: IccReceiptXApi;
  agendaApi: IccAgendaApi;
  calendarItemApi: IccCalendarItemXApi;
  classificationApi: IccClassificationXApi;
  timetableApi: IccTimeTableXApi;
  groupApi: IccGroupApi;
  nonDecryptedApis: nonDecryptedApis;
}

export interface nonDecryptedApis {
  accesslogApi: IccAccesslogApi;
  calendarItemApi: IccCalendarItemApi;
  contactApi: IccContactApi;
  patientApi: IccPatientApi;
}