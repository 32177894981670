import { useTranslation } from "react-i18next";

const NewPatientQuestion = function  (props:{
        healthCarePartyName:string,
        onSubmit:(property:{isNewPatient:boolean
    }) => void }){
    const {t} = useTranslation();
    return (
        <div className="new-patient-question">
            <h3 className ="screen-title">{t("FLOW.NEW_PATIENT_QUESTION.TITLE")}</h3>
            <button className="button secondary" onClick={() => props.onSubmit({isNewPatient:false})}>{t("FLOW.NEW_PATIENT_QUESTION.YES")}</button>
            <button className="button secondary" onClick={() => props.onSubmit({isNewPatient:true})}>{t("FLOW.NEW_PATIENT_QUESTION.NO")}</button>
        </div>
    );
}
export default NewPatientQuestion;