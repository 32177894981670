import {validateCode, renewPatientToken} from "../../../services/apiService";
import {ERROR, Patient} from "./types";
import {savePatient} from "../../service/localStorage";
import * as Sentry from "@sentry/browser";

export const isValidCode = (code: string): boolean =>
    code.length === 6 &&
    Boolean(code.match(/[0-9]{6,6}/));

export const checkVerificationCodeAndUpdatePatient = async (
    requestId: string,
    groupId:string,
    visitedHealthcarePartyId:string,
    patient:Patient,
    code: string,
    rememberMe:boolean,
    ):Promise<Patient> => {

    if (!isValidCode(code)) {
        throw ERROR.ID_CODE_INVALID;
    }

    try {
        await validateCode(requestId, code);
    } catch (e) {
        Sentry.captureException(e);
        throw(e);
    }
    try {
        const updatedPatient:Patient  = {
            ...await renewPatientToken(patient, groupId, code),
            visitedHealthcarePartyIds:
                visitedHealthcarePartyId?Array.from (new Set([...(patient.visitedHealthcarePartyIds ||  []), visitedHealthcarePartyId])):patient.visitedHealthcarePartyIds
        }
        if (rememberMe) {
            savePatient(updatedPatient);
        }
        return updatedPatient;
    }
    catch (e) {
        console.error(e);
        Sentry.captureException(e);
        throw ERROR.ID_SERVER;
    }
}