import {formatHealthcarePartyName, getUniquePlacesFromAppointmentTypesAndHCP, selectAppointmentTypesByPlaceId} from "../services/utils";
import {AppointmentTypeAndPlace, HealthcareParty, Place } from "@icure/api";
import React from "react";
import {useTranslation} from "react-i18next";
import AddressBlock from "./AddressBlock";

interface HeaderProps {
    healthcareParty:HealthcareParty,
    appointmentTypes:AppointmentTypeAndPlace[],
    placeId:string,
    cancelFlow:()=>void
}

const AppointmentFlowHeader = ({healthcareParty, cancelFlow, placeId, appointmentTypes}:HeaderProps)  => {
    const {t} = useTranslation();
    const places = getUniquePlacesFromAppointmentTypesAndHCP(appointmentTypes!,healthcareParty)
    return (
    <div className="flow-header">
        <h3 className="main-subtitle">{formatHealthcarePartyName(healthcareParty as HealthcareParty)}</h3>
        <div className="nav">
            <h2 className="medium-title">{t("FLOW.NEW_APPOINTMENT")}</h2>
           <button className="cancel-button" onClick={() => cancelFlow ()} />
        </div>
        <AddressBlock 
            acceptsChange={places.length > 1}
            acceptsAppointments={true}
            isExpanded = {false}
            onChange={()=>cancelFlow()}
            place={places.find((place:Place)=> place.id! === placeId)!}

        />
    </div>)
}

export default AppointmentFlowHeader;