import {
    AppointmentTypeAndPlace,
    CalendarItem,
    IccAnonymousAccessApi,
    ua2hex,
    User,
    UserAndHealthcareParty,
    HealthcareParty,
    hex2ua,
} from "@icure/api/";

import * as Sentry from "@sentry/browser";
import {Patient as IcurePatient} from '@icure/api/';
import dayjs from "dayjs";
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
    Patient,
    MailData,
    IcureApi, ERROR, TokenByGroup,
} from "../components/appointment-flow-screens/service/types";
import {FlowState} from "../components/AppointmentFlow";
import {
    formatHealthcarePartyName,
    buildCalendarItem,
    getNow,
    getOneYearFrom,
    getMillisecondsBetweenNowAndDate,
    getHost,
    formatAddress, findPatientToken, invalidateToken, PATIENT_APP_TAG, sendEmail, cancelReminder, getAgendaConfiguration, DEFAULT_PLACE_ID, formatTelecoms
} from "./utils";
import {IccUserApi, retry} from '@icure/api/';
import {getAppId} from "../components/service/localStorage";
import i18n from "i18next";
import {getApi} from "./apiProviderService";
import {LoginCredentials} from "@icure/api";
import {API_TIME_FORMAT, ICURE_HOST, MESSAGE_HOST, PROCESS_ID, SPEC_ID} from "./constants"
import {UserGroup} from "@icure/api/icc-api/model/UserGroup";

dayjs.extend(customParseFormat);


const aaApi = new IccAnonymousAccessApi(ICURE_HOST, {});
const SIX_MONTHS: number = .5 * 365 * 24 * 60 * 60;

let icurePatientPromise: Promise<IcurePatient | undefined | null> | undefined;
let patientUserPromise: Promise<User | null | undefined> | undefined;
let healthcarePartyAncestorPromise: Promise<HealthcareParty> | undefined;

const getAncestor = async (healthcarePartyApi: any, healthcareParty: HealthcareParty): Promise<HealthcareParty> => {
    if (healthcareParty.parentId) {
        return await getAncestor(healthcarePartyApi,
            await getFullHealthcareParty(healthcarePartyApi, healthcareParty.parentId));
    }
    return healthcareParty;
}

export const gethealthcarepartyAncestor = async (healthcarePartyApi?:any, healthcareParty?:HealthcareParty):Promise<HealthcareParty> => {
    if (healthcarePartyAncestorPromise) {
            return healthcarePartyAncestorPromise;
    }
    if (!healthcarePartyApi || !getFullHealthcareParty) {
        throw new Error ("You need to provide healthCarePartyApi and a base healthcareParty as ancestor is not yet cached");
    }

    return healthcarePartyAncestorPromise = getAncestor(healthcarePartyApi,
        healthcareParty!.parentId ?
        healthcareParty!:
        await getFullHealthcareParty(healthcarePartyApi, healthcareParty!.id!));
}

const getUserApi = async (username: string, password: string): Promise<IccUserApi> => (await getApi({
    username,
    password
})).userApi;


export const getUserAndHealthcareParty = async (groupId: string, healthCarePartyId: string): Promise<UserAndHealthcareParty> => {
    const list: UserAndHealthcareParty[] | undefined = await aaApi.listHealthcarePartiesInGroup(groupId);
    const result: UserAndHealthcareParty | undefined = list.find(({healthcareParty}) => healthcareParty?.id === healthCarePartyId);
    if (!result) {
        throw new Error(i18n.t("UI.ERROR.HCPS_IN_GROUP") as string,{cause:list});
    }
    return result;
};

export const getUserAndHealthcareParties = async (groupId: string): Promise<UserAndHealthcareParty[]> => {
  const list: UserAndHealthcareParty[] | undefined =  await aaApi.listHealthcarePartiesInGroup(groupId);
  if (!list) {
      throw new Error(i18n.t("UI.ERROR.HCPS_IN_GROUP") as string,{cause:list});
  }
  return list;
};


export const getAppointmentTypes = async (groupId: string, userId: string): Promise<AppointmentTypeAndPlace[]> => {
    return retry(() => aaApi.listAppointmentTypesForUser(groupId, userId, getNow(), getOneYearFrom(getNow())), 5, 500);
};

export const getTimeSlots = async (groupId: string, userId: string, hcpId: string, calendarItemTypeId: string, placeId: string, isNewPatient: boolean, startTime?:number) => {
    return retry(() => aaApi.getAvailabilitiesByPeriodAndCalendarItemTypeId(groupId, userId, calendarItemTypeId, isNewPatient, startTime ?? getNow(), getOneYearFrom(startTime ?? getNow()), hcpId, placeId === DEFAULT_PLACE_ID ? undefined  : placeId), 5, 500);
}

export const checkTimeSlotAvailability = async (groupId: string, userId: string, hcpId: string, {duration, placeId, calendarItemTypeId}:AppointmentTypeAndPlace, isNewPatient: boolean, timeslot: number) => {
    const endTime = parseInt(dayjs(timeslot.toString(),API_TIME_FORMAT).add(duration!,"minute").format(API_TIME_FORMAT))
     return (await retry(() => aaApi.getAvailabilitiesByPeriodAndCalendarItemTypeId(groupId, userId, calendarItemTypeId!, isNewPatient, timeslot, endTime, hcpId, placeId), 5, 500)).includes(timeslot);
}

export const sendConfirmationEmail = async ({
                                                patient,
                                                healthcareParty,
                                                groupId,
                                                timeslot
                                            }: FlowState, calendarItem: CalendarItem) => {
    const {email, tokensByGroup} = patient!;
    const patientToken = findPatientToken(patient?.tokensByGroup!, groupId!);
    if (!patientToken) {
        throw new Error(`No token found in patient for groupId: ${groupId}.  
        Tokens only available for groupIds: ${tokensByGroup.map(({groupId})=> groupId).join(', ')}.`);
    }
    const {id: userId}: User = (await getUser(patient!, groupId!))!;
    const calendarItemToken:string | undefined =
        (await getNewTokens(patient!, groupId!,undefined, `ci-${calendarItem.id}`, getMillisecondsBetweenNowAndDate(String(timeslot)))).find((item)=> item.groupId === groupId)!.token;
    if (!calendarItemToken) {
        throw (new Error ("No token found"));
    }
    const healthCarePartyUrl: string = `${getHost()}${encodeURI(groupId!)}/${encodeURI(healthcareParty?.id!)}`;
    const calendarItemUrl: string = `${healthCarePartyUrl}/${calendarItem.id}/${userId}/${calendarItemToken}`;
    const longDate: String = dayjs(String(timeslot), API_TIME_FORMAT).format('ddd DD/MM, HH:mm');
    const month: string = dayjs(String(timeslot), API_TIME_FORMAT).format("MMM");
    const day: string = dayjs(String(timeslot), API_TIME_FORMAT).format("DD");
    const hcpName: string = formatHealthcarePartyName(healthcareParty!);
    const address: string = formatAddress(calendarItem?.address!);
    const telecoms: string = formatTelecoms(calendarItem?.address!);

    const addressWithTelecoms = `${address} ${telecoms}`



    await sendEmail(email!,
                    patientToken,
                    `${i18n.t('FLOW.SAVE_APPOINTMENT.APPOINTMENT_CONFIRMATION')}: ${formatHealthcarePartyName(healthcareParty!)} - ${dayjs(String(timeslot), API_TIME_FORMAT).format("DD MMM YY - HH:mm")}`,
                    i18n.t("emails:confirmation", {
                        calendarItemUrl,
                        healthCarePartyUrl,
                        longDate,
                        month,
                        day,
                        hcpName,
                        address:addressWithTelecoms
                    })
    );

    // Send a reminder email
    const config = getAgendaConfiguration(healthcareParty!)
    const reminderTime = dayjs(String(timeslot)).subtract(config.sendPatientEmailReminderDelayBeforeAppointment ?? 24,"hours")

    if (config.sendPatientEmailReminder && reminderTime.isAfter(dayjs())) {
        return await sendEmail(email!,
            patientToken,
            `${i18n.t('FLOW.REMIND_APPOINTMENT.EMAIL_SUBJECT')} ${formatHealthcarePartyName(healthcareParty!)} - ${dayjs(String(timeslot), API_TIME_FORMAT).format("DD MMM YY - HH:mm")}`,
            i18n.t("emails:reminder", {
                calendarItemUrl,
                healthCarePartyUrl,
                longDate,
                month,
                day,
                hcpName,
                address
            }),
            reminderTime.valueOf(),
            //dayjs().add(2,'minutes').valueOf(), // for development purposes
            calendarItem.id
        )
    }


}



export const sendCancellationConfirmationEmail = async ({
                                                patient,
                                                healthcareParty,
                                                groupId,
                                                timeslot
                                            }: FlowState,
                                            confirmationId:string) => {

    const {tokensByGroup} = patient!;
    const user = await patientUserPromise;
    const patientToken = findPatientToken(tokensByGroup, groupId!);
    if (!patientToken) {
        throw new Error(`No token found in patient for groupId: ${groupId}.  
        Tokens only available for groupIds: ${Object.keys(tokensByGroup.map(({groupId}) => groupId).join(', '))}.`);
    }
    const healthCarePartyUrl: string = `${getHost()}${encodeURI(groupId!)}/${encodeURI(healthcareParty?.id!)}`;
    const longDate: String = dayjs(String(timeslot), API_TIME_FORMAT).format('ddd DD/MM, HH:mm');
    const hcpName: string = formatHealthcarePartyName(healthcareParty!);
    if (confirmationId) cancelReminder(confirmationId,user?.email!,patientToken)
    await sendEmail(user?.email!,
                    patientToken,
                    i18n.t('FLOW.CANCEL_APPOINTMENT.EMAIL_SUBJECT',{name:formatHealthcarePartyName(healthcareParty!)}),
                    i18n.t("emails:cancellation", {
                        healthCarePartyUrl,
                        longDate,
                        hcpName
                    })
    )
}

export const sendValidationEmail = async (
  recaptchaToken: string,
  { patient, healthcareParty, groupId }: FlowState,
  requestId: string
) => {
  const { firstName, lastName, email, phoneNumber } = patient!;
  const postData: MailData = {
    "g-recaptcha-response": recaptchaToken,
    firstName: firstName!,
    lastName: lastName!,
    emailAddress: email!,
    mobilePhone: phoneNumber!,
    group: groupId!,
    from: email!,
    hcp: formatHealthcarePartyName(healthcareParty!),
    hcpId: healthcareParty?.id!,
  };
  const {ok, statusText}: Response = await
  fetch(`${MESSAGE_HOST}/${SPEC_ID}/process/${PROCESS_ID[i18n.language ?? "fr"]}/${requestId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(postData),
    }
  );
  if (!ok) {
    throw new Error(statusText);
  }
};

export const validateCode = async (
  requestId: string,
  code: string
): Promise<void> => {
  const result: Response = await retry(async () => await fetch(`${MESSAGE_HOST}/${SPEC_ID}/process/validate/${requestId}-${code}`), 10, 500)
  if (result.status === 404) {
    throw ERROR.ID_CODE_WRONG;
  }
};

const getNewTokens = async (
  patient: Patient,
  groupId: string,
  tempToken?: string,
  tokenId: string = getAppId(),
  validity: number = SIX_MONTHS
): Promise<TokenByGroup[]> => {
  if (!groupId) {
    throw new Error("GroupId missing");
  }
  if (!patient.email) {
    throw new Error("No email found");
  }
  const NAMESPACE = "be.medispring.online.calendar";
  const putCurrentGroupAtEnd = (a: UserGroup) =>
    a.groupId === groupId ? 1 : -1;
  const password: string | undefined =
    tempToken || findPatientToken(patient.tokensByGroup, groupId)!;
  if (!password) {
    throw new Error("No token found !");
  }
  const { userApi }: IcureApi = await getApi({
    username: patient.email!,
    password,
  });
  const userGroups: UserGroup[] = await userApi.getMatchingUsers();

  return Promise.all(
    userGroups.sort(putCurrentGroupAtEnd).map(async ({ groupId, userId }) => {
      const { userApi }: IcureApi = await getApi({
        username: `${groupId}/${userId}`,
        password,
      });
      return {
        groupId: groupId!,
        token: await userApi.getToken(
          userId!,
          `${NAMESPACE}-${tokenId}`,
          validity
        ),
      };
    })
  );
};

export const renewPatientToken = async (
  patient: Patient,
  groupId: string,
  tempToken?: string
): Promise<Patient> => {
  const token: string | undefined =
    tempToken || findPatientToken(patient.tokensByGroup, groupId!);
  if (!token) {
    throw new Error("No token found.");
  }

  try {
    patient.userId =
      patient.userId || (await getUser(patient, groupId, tempToken))!.id;
    const tokensByGroup: TokenByGroup[] = await getNewTokens(
      patient,
      groupId,
      token
    );
    if (!findPatientToken(tokensByGroup, groupId)) {
      throw new Error("No token found for current groupId");
    }
    clearCache();
    return {
      ...patient,
      tokensByGroup,
    };
  } catch (e) {
    console.warn(
      "Failed to generate new token on basis of existing token. Existing token has been removed. New eMail authentification needed."
    );
    return {
      ...patient,
      tokensByGroup: invalidateToken(patient.tokensByGroup, groupId),
    };
  }
};

export const configureNewPatient = async (
  patient: Patient,
  healthcareParty: HealthcareParty,
  groupId: string
) => {
  const patientUser: User = (await getUser(patient, groupId))!;
  const password = findPatientToken(patient.tokensByGroup, groupId);
  const username: string = `${groupId}/${patientUser.id!}`;
  if (!password) {
    throw new Error(`No token found for groupId ${groupId}`);
  }
  if (!patientUser.patientId && patientUser.healthcarePartyId) {
    throw new Error(ERROR.IS_MEDISPRING_USER);
  }
  const api: IcureApi = await getApi({ username, password });
  const hasNewKeys = await createPatientKeys(
    { password, username },
    patientUser
  );
  const icurePatient: IcurePatient = await retry(
    () =>
      api.patientApi.getPatientWithUser(patientUser, patientUser.patientId!),
    10,
    500
  );
  if (hasNewKeys) {
    icurePatientPromise = addHcpDelegationsAndFlagPatient(
      { password, username },
      icurePatient,
      patientUser,
      healthcareParty
    );
  }
};

const createPatientKeys = async (
  credentials: LoginCredentials,
  patientUser: User
): Promise<boolean> => {
  const { cryptoApi, patientApi, maintenanceTaskApi } = await getApi(
    credentials
  );
  const rawPatient: IcurePatient = await retry(
    () => patientApi.getPatientRaw(patientUser.patientId!),
    5,
    1000
  );

  if (!rawPatient.publicKey) {
    const { publicKey, privateKey } = await cryptoApi.RSA.generateKeyPair();
    const publicKeyHex = ua2hex(
      await cryptoApi.RSA.exportKey(publicKey!, "spki")
    );
    await retry(
      () =>
        patientApi.modifyPatientRaw({ ...rawPatient, publicKey: publicKeyHex }),
      5,
      1000
    );
    await cryptoApi.loadKeyPairsAsTextInBrowserLocalStorage(
      rawPatient.id!,
      new Uint8Array(
        (await cryptoApi.RSA.exportKey(privateKey!, "pkcs8")) as ArrayBuffer
      )
    );
    const keyPairInJwk = await cryptoApi.RSA.exportKeys(
      { publicKey, privateKey },
      "jwk",
      "jwk"
    );
    await cryptoApi.cacheKeyPair(keyPairInJwk);
    return true;
  }
  // Si le patient a déjà une clef publique
  // On checke si on a en local une clef privée qui correspond
  const isValid = await cryptoApi.checkPrivateKeyValidity(rawPatient);
  // Si pas, on génère une nouvelle paire de clefs
  if (!isValid) {
    const { privateKey } = await cryptoApi.addNewKeyPairForOwnerId(
      maintenanceTaskApi,
      patientUser,
      patientUser.patientId!
    );
    await cryptoApi.loadKeyPairsAsTextInBrowserLocalStorage(
      rawPatient.id!,
      hex2ua(privateKey)
    );
  }
  return false;
};

const addHcpDelegationsAndFlagPatient = async (
  credentials: LoginCredentials,
  patient: IcurePatient,
  user: User,
  delegate: HealthcareParty
): Promise<IcurePatient | null | undefined> => {
  const { patientApi, healthcarePartyApi } = await getApi(credentials);
  try {
    return await patientApi.modifyPatientWithUser(user, {
      ...(await patientApi.initDelegationsAndEncryptionKeys(
        patient,
        user,
        undefined,
        [
          delegate.id!,
          (await gethealthcarepartyAncestor(healthcarePartyApi, delegate)).id!,
        ]
      )),
      tags: [...patient.tags!, PATIENT_APP_TAG],
    });
  } catch {
    console.warn(
      "Configuration of new patient failed: no delegations added. This shouldn't prevent the appointment but the hcp will not be able to access this patient in Medispring."
    );
  }
  return null;
};

export const saveCalendarItem = async ({
  patient,
  appointmentType,
  healthcareParty,
  timeslot,
  groupId,
  user,
  appointmentNote,
}: FlowState): Promise<CalendarItem> => {
  const { tokensByGroup }: Patient = patient!;
  const password: string | undefined = findPatientToken(
    tokensByGroup,
    groupId!
  );
  if (!appointmentType) {
    throw new Error("No appointment type provided");
  }
  if (!password) {
    throw new Error(`No token found in patient for groupId: ${groupId}.  
        Tokens only available for groupIds: ${tokensByGroup
          .map(({ groupId }) => groupId)
          .join(", ")}.`);
  }
  const patientUser: User = (await getUser(patient!, groupId!))!;
  const username: string = `${groupId}/${patientUser.id!}`;
  const {
    calendarItemApi,
    agendaApi,
    healthcarePartyApi,
    patientApi,
  }: IcureApi = await getApi({ username, password });

  createPatientKeys({ username, password }, patientUser);

  const { id: agendaId } = await retry(
    () => agendaApi.getAgendasForUser(user?.id!),
    5,
    500
  );
  let calendarItem: CalendarItem = buildCalendarItem(
    patientUser.id!,
    healthcareParty!,
    agendaId!,
    patient!,
    appointmentType!,
    timeslot!,
    appointmentNote
  );

  const highestHcpId: string | undefined = (await gethealthcarepartyAncestor(
    healthcarePartyApi,
    healthcareParty
  ))!.id!;
  try {
    const body = await retry(
      async () =>
        await calendarItemApi.newInstance(patientUser, calendarItem, [
          healthcareParty!.id!,
          highestHcpId!,
          patientUser.patientId!,
        ]),
      5,
      500
    );
    const calendarItemWithHCP: CalendarItem = await retry(
      async () =>
        calendarItemApi.createCalendarItemWithHcParty(patientUser, body),
      5,
      500
    );

    await sendConfirmationEmail(
      {
        patient,
        healthcareParty,
        timeslot,
        groupId,
      },
      calendarItemWithHCP
    ).catch((e) => Sentry.captureException(e));
    return calendarItemWithHCP;
  } catch (e) {
    const icurePatient =
      (await icurePatientPromise) ||
      (await patientApi.getPatientWithUser(
        patientUser,
        patientUser?.patientId!
      ));
    if (icurePatient.deletionDate) {
      throw new Error(ERROR.DELETED_PATIENT);
    }
    throw e;
  }
};

export const modifyCalendarItem = async (
  calendarItem: CalendarItem,
  patient: Patient,
  groupId: string
) => {
  const user: User = (await getUser(patient, groupId))!;
  const { calendarItemApi } = await getApi({
    username: `${groupId}/${patient.userId!}`,
    password: findPatientToken(patient.tokensByGroup, groupId)!,
  });
  return await retry(
    () => calendarItemApi.modifyCalendarItemWithHcParty(user, calendarItem),
    5,
    500
  );
};

export const modifyCalendarItemRaw = async (
  calendarItem: CalendarItem,
  patient: Patient,
  groupId: string
) => {
  const { nonDecryptedApis } = await getApi({
    username: `${groupId}/${patient.userId!}`,
    password: findPatientToken(patient.tokensByGroup, groupId)!,
  });
  return await retry(
    () => nonDecryptedApis.calendarItemApi.modifyCalendarItem(calendarItem),
    5,
    500
  );
};

export const deleteCalendarItem = async (calendarItemId: string, patient: Patient, groupId: string) => {
    const {calendarItemApi} = await getApi({
        username: `${groupId}/${patient.userId!}`,
        password: findPatientToken(patient.tokensByGroup, groupId)!
    });
    await retry(() => calendarItemApi.deleteCalendarItems({ids: [calendarItemId]}), 10, 500);
}

export const getCalendarItem = async (
  calendarItemId: string,
  patient: Patient,
  groupId: string
): Promise<CalendarItem> => {
  const user: User = (await getUser(patient, groupId))!;
  const { calendarItemApi, cryptoApi } = await getApi({
    username: `${groupId}/${patient.userId!}`,
    password: findPatientToken(patient.tokensByGroup, groupId)!,
  });
  await loadKeysFromLocalStorage(user.patientId!, patient, groupId);

  return await retry(
    () => calendarItemApi.getCalendarItemWithUser(user, calendarItemId),
    10,
    500
  );
};

// TODO: this method is a fix/modification for cryptoApi.loadKeysFromLocalStorage that skips keys that are not in the local storage
export const loadKeysFromLocalStorage= async (dataOwnerId: string,
    patient: Patient,
    groupId: string): Promise<void> => {
    const { cryptoApi } = await getApi({
        username: `${groupId}/${patient.userId!}`,
        password: findPatientToken(patient.tokensByGroup, groupId)!,
      });

    const dataOwner = (await cryptoApi.getDataOwner(dataOwnerId)).dataOwner
    const pubKeys = new Set((dataOwner.publicKey ? [dataOwner.publicKey] : [])
          .concat(dataOwner.aesExchangeKeys ? Object.keys(dataOwner.aesExchangeKeys) : [])
          .filter((pubKey) => !!pubKey)
      )

    for (const pk of Array.from(pubKeys)) {
        const fingerprint = pk.slice(-32)
        if (cryptoApi.rsaKeyPairs[fingerprint]) continue
      
        const keyPair = await cryptoApi.loadKeyPairNotImported(dataOwnerId, fingerprint)
        if (!keyPair) continue
        await cryptoApi.cacheKeyPair(keyPair)
    }
  }

export const getUser = async (patient: Patient, groupId?: string, tempToken?: string): Promise<User | null | undefined> => {
    const {email, userId} = patient;
    const login: string | undefined = userId || email;
    const password: string | undefined = tempToken || findPatientToken(patient.tokensByGroup, groupId!);
    if (patientUserPromise) {
        return patientUserPromise;
    }
    if (!login) {
        throw new Error("No login found.");
    }
    if (!password) {
        throw new Error("No password found.");
    }
    const userApi = await getUserApi(`${userId && groupId ? `${groupId}/` : ''}${login}`, password);
    return await (patientUserPromise = retry(() => userApi.getCurrentUser(), 5, 500));
}

const getFullHealthcareParty = async(healthcarePartyApi: any, healthcarePartyId: string): Promise<HealthcareParty> => {
    return retry(() => healthcarePartyApi.getHealthcareParty(healthcarePartyId), 5, 500);
}

export const clearCache = () => {
    patientUserPromise = undefined;
    icurePatientPromise = undefined;
}
