export const TRANSLATIONS_NL = {
    UI: {
        INFORMATION:"Informatie",
        PLACE: "Plaats",
        LOADING: "Laden...",
        SAVING: "Uw afspraak wordt opgeslagen...",
        HEADER: {
            APP_NAME: "Online Agenda",
            POWERED_BY: "Aangedreven door Medispring"
        },
        ERROR: {
            TITLE: "Selecteer een zorgverlener.",
            SUBTITLE: "",
            NO_AGENDA: "Geen agenda op dit adres",
            HCPS_IN_GROUP: "Er zijn meerdere zorgverleners beschikbaar op dit adres. Met wie wilt u een afspraak maken?"
        }
    },
     TOOLTIP : {
        REMEMBER_ME: "Als u deze optie aanvinkt, worden uw gegevens op deze computer opgeslagen, zodat u deze niet opnieuw hoeft in te voeren bij uw volgende bezoek. Selecteer deze optie niet als u deze computer met anderen deelt."
    },
     BUTTONS: {
        START_APPOINTMENT: "Maak afspraak",
        CANCEL: "Annuleren",
        NEXT: "Volgende",
        CONFIRM: "Bevestigen",
        NEW_APPOINTMENT: "Nieuwe afspraak",
        OTHER_APPOINTMENT: "Andere afspraak",
        CHANGE: "Veranderen",
        VIEW_ITINERARY: "Zie routebeschrijving",
        },
    FLOW: {
        NEW_APPOINTMENT: "Nieuwe afspraak",
        NEW_PATIENT_QUESTION: {
            TITLE: "Heeft u deze zorgverlener al eerder bezocht?",
            YES: "Ja",
            NO: "Nee"
        },
        NEW_PATIENT_REJECTION: {
            TEXT: "<p>Sorry, deze zorgverlener biedt online afspraken maken alleen aan patiënten die al onder behandeling zijn.</p>"
        },
        CALENDAR_ITEM_TYPE_CHOICE: {
            TITLE: "Kies een afspraaktype: "
        },
        TIMESLOT_CHOICE: {
         TITLE: "Kies een tijdslot: ",
         NO_TIMESLOTS: "Er zijn geen online beschikbare tijden, neem contact op met de receptie om een afspraak te maken.",
         SHOW_MORE: "Meer tijden weergeven",
         NO_MORE: "😥 Geen online beschikbaarheid meer na deze datum. Neem alstublieft contact met ons op om een afspraak te maken."
        },
        IDENTIFICATION: {
            ERROR: "Vul de volgende velden correct in: {{missingFields}}.",
            TITLE: "Uw gegevens: ",
            FIRSTNAME: "Voornaam",
            LASTNAME: "Achternaam",
            EMAIL: "E-mail",
            PHONE: "Telefoonnummer",
            REASON: "Reden voor uw bezoek (optioneel)",
            REMEMBER_ME: "Onthoud mij",
            POLICY: "Door te klikken op {{action}}, gaat u akkoord met de voorwaarden van de <a href='/terms' target='_blank'>Gebruiksvoorwaarden</a> van dit afsprakensysteem",
            REVIEW: "Uw informatie wordt veilig verzonden. Online afspraak maken is geen vervanging voor een consultatie. Door deze afspraak te bevestigen, verbindt u zich ertoe deze na te komen. Vergeet niet om in geval van onvoorziene omstandigheden zo snel mogelijk te annuleren",
            WARNING: "Let op: er komt nog een laatste stap om uw afspraak te bevestigen."
        },
        VERIFICATION_CODE: {
            CALL_TO_ACTION: "Er is een code van 6 cijfers naar <strong>{{email}}</strong> gestuurd.<br/>Voer deze code hieronder in om uw e-mailadres te verifiëren en uw afspraakverzoek te bevestigen."
        },
        SAVE_APPOINTMENT: {
            FROM_MEDISPRING:"Via Medispring: ",
            APPOINTMENT_CONFIRMATION: "Bevestiging afspraak: ",
            CONFIRMATION:"Uw afspraak met <strong>{{name}}</strong> op <strong>{{day}} om {{time}}</strong> is bevestigd. Vergeet uw identiteitsdocumenten niet mee te nemen.</p><p>Dank u en tot ziens!"
         },
        CANCEL_APPOINTMENT: {
            HEADER:"Deze afspraak annuleren?",
            BODY:"U staat op het punt om uw afspraak te annuleren. Klik op de onderstaande knop om uw afspraak te annuleren.",
            EMAIL_SUBJECT:"Afspraakannulering met {{name}}",
            CONFIRMATION_HEADER: "Afspraak geannuleerd",
            CONFIRMATION_BODY: "Uw afspraak is succesvol geannuleerd. Dank u voor het tijdig doorgeven van de annulering. Dank voor uw vertrouwen."
        },
         REMIND_APPOINTMENT: {
            EMAIL_SUBJECT:"Herinnering aan uw afspraak: "
        }
    },
    ERRORS: {
        ID_CODE_INVALID: "De code moet uit 6 cijfers bestaan.",
        ID_CODE_WRONG: "Er is een fout opgetreden. Controleer of de code die u heeft ingevuld overeenkomt met de code die u per e-mail heeft ontvangen.",
        GENERIC: "Er is een technische fout opgetreden. Probeer het opnieuw.",
        IS_MEDISPRING_USER: "Dit e-mailadres kan niet worden gebruikt om een afspraak te maken met deze praktijk, omdat het al is gekoppeld aan een Medispring-gebruiker in de gezondheidszorg. Gebruik alstublieft een ander e-mailadres."
        },
    FOOTER:{
        LEGAL: "Wettelijke Vermeldingen",
        PRIVACY: "Privacybeleid",
        TOU:"Gebruiksvoorwaarden",
        HELP:"Hulp nodig?",
        FAQ:"Veelgestelde vragen "
    },
    "SPECIALITIES": {
        "ADMINISTRATION": "Administratie",
        "CARE_GIVER": "Zorgverlener",
        "ANATOMOPATHOLOGY": "Anatomopatologie",
        "ANESTHESIOLOGY": "Anesthesiologie",
        "SOCIAL_WORKER": "Sociaal assistent",
        "PHARMA_ASSISTANT": "Farmaceutisch-technisch assistent",
        "AUDIOLOGIST": "Audioloog",
        "BACTERIOLOGY": "Bacterioloog",
        "TRUSS_ORTHOTIST": "Bandagist-orthopedisch instrumentmaker",
        "CLINICAL_BIOLOGY": "Klinische biologie",
        "CARDIOLOGY": "Cardiologie",
        "GENERAL_SURGERY": "Algemene chirurgie",
        "MAXILLOFACIAL_SURGERY": "Maxillofaciale chirurgie",
        "PLASTIC_SURGERY": "Plastische chirurgie",
        "STOMATOLOGICAL_SURGERY": "Stomatologische chirurgie",
        "CARDIOVASCULAR_SURGERY": "Cardio-vasculaire chirurgie",
        "ABDOMINAL_SURGERY": "Abdominale chirurgie",
        "DENTISTRY": "Tandheelkunde",
        "DERMATOLOGY": "Dermatologie",
        "DIETETIC": "Dieetkunde",
        "ENDOCRINOLOGY": "Endocrinologie ",
        "OCCUPATIONAL_THERAPIST": "Ergotherapeut",
        "GASTROENTEROLOGY": "Gastro-enterologie",
        "GENETIC": "Genetica",
        "GERIATRICS": "Geriatrie",
        "GYNECOLOGY": "Gynaecologie / Verloskunde",
        "HEMATOLOGY": "Hematologie",
        "NURSE": "Verpleegkundige/verpleegster",
        "NURSE_EDUCATOR": "Verpleegkundige opvoeder",
        "PHYSIOTHERAPY": "Kinesitherapie",
        "SPEECH": "Logopedie",
        "ACUTE_MEDICINE": "Acute geneeskunde",
        "GENERAL_MEDICINE": "Algemene geneeskunde",
        "persphysician": "Algemene geneeskunde",
        "INTERNAL_MEDICINE": "Interne geneeskunde",
        "NUCLEAR_MEDICINE": "Nucleaire geneeskunde",
        "PHYSICAL_MEDICINE": "Fysiotherapie",
        "EXPERTISE_MEDICINE": "Deskundige geneeskunde",
        "INSURANCE_MEDICINE": "Verzekeringsgeneeskunde",
        "TRAVEL_MEDICINE": "Reisgeneeskunde",
        "NEONATOLOGY": "Neonatologie",
        "NEPHROLOGY": "Nefrologie",
        "NEUROSURGERY": "Neurochirurgie",
        "NEUROLOGY": "Neurologie",
        "NEUROPEDIATRICS": "Neuropediatrie",
        "NEUROPSYCHIATRY": "Neuropsychiatrie ",
        "NUTRITIONIST": "Nutritionist(e)",
        "ONCOLOGY": "Oncologie",
        "OPHTHALMOLOGY": "Oogheelkunde",
        "ORTHOPEDICS": "Orthopedie",
        "ORTHOPTISTE": "Orthoptist",
        "OTORHINOLARYNGOLOGY": "Otorhinolaryngologie",
        "PEDIATRICS": "Pediatrie",
        "PHARMACOLOGY": "Farmacologie",
        "RESPIRATORY": "Pneumologie",
        "PODIATRIST": "Podoloog",
        "PUBLIC_HEALTH_PREVENTION": "Preventie van volksgezondheid",
        "PROSTHETIST": "Prothesist",
        "PSYCHIATRY": "Psychiatrie ",
        "MEDICAL_PSYCHOLOGY": "Medische psychologie",
        "RADIODIAGNOSTICS": "Radiodiagnostiek / Medische beeldvorming",
        "RADIOTHERAPY": "Radiotherapie",
        "RESUSCITATION": "Reanimatie",
        "RHEUMATOLOGY": "Reumatologie",
        "MIDWIFE": "Vroedvrouw",
        "STOMATOLOGY": "Stomatologie",
        "EMERGENCY": "Urgenties",
        "UROLOGY": "Urologie",
        "VIROLOGY": "Virologie",
        "TOXICOLOGY": "Toxicologie",
        "TRANSPORT": "Ziektetransport",
        "ALGOLOGY": "Algologie",
        "GUARDHOUSE": "Wachtdienst",
        "REVALIDATION": "Revalidatien",
        "SENOLOGY": "Senologie",
        "DIABETOLOGY": "Diabetologie",
        "INFECTIOLOGY": "Besmettelijke ziektes",
        "KINESIOLOGY": "Kinesiologie",
        "PHYSIOTHERAPYMICRO": "Kinesitherapie: micro",
        "OSTHEAOPATIA": "Osteophatie",
        "PSYCHOTHERAPY": "Psychotherapie",
        "OTHER": "Andere",
        "HYPNOSIS": "Hypnose",
        "PERSPHYSICIAN": "Arts",
        "NO_SPECIALITY": "Geen specialiteit"
    }
};