import React, {useEffect, useState} from "react";
import {APPOINTMENT_FLOW_SCREENS} from "./appointmentScreenlist";
import NewPatientQuestion from "./appointment-flow-screens/NewPatientQuestion";
import NewPatientRejection from "./appointment-flow-screens/NewPatientRejection";
import Identification from "./appointment-flow-screens/Identification";
import { gotoNextValid, gotoFirstValid, gotoScreenName, initScreenList} from "./service/FlowManagerService";
import {findPatientToken, formatHealthcarePartyName, getScrollPositionForScreen, selectPhoneNumberFromHealthcareParty} from "../services/utils";
import CalendarItemTypeChoice from "./appointment-flow-screens/CalendarItemTypeChoice";
import TimeslotChoice from "./appointment-flow-screens/TimeslotChoice";
import Validation from "./appointment-flow-screens/Validation"
import {HealthcareParty, User} from "@icure/api";
import {renewPatientToken } from "../services/apiService";
import {Patient, ScreenName} from "./appointment-flow-screens/service/types";
import {getPatient, savePatient} from "./service/localStorage";
import {SaveAppointment} from "./appointment-flow-screens/SaveAppointment";
import {AppointmentTypeAndPlace} from "@icure/api/";
import { trackGoal } from "fathom-client";
import { FathomEvent } from "../constants/fathom.constants";
import PlaceChoice from "./appointment-flow-screens/PlaceChoice";
import AppointmentFlowHeader from "./AppointmentFlowHeader";

export interface FlowState {
    user?:User
    patient?:Patient,
    screenName?:string,
    healthcareParty?:HealthcareParty,
    isNewPatient?:boolean,
    groupId?:string;
    timeslot?:number,
    token?:string;
    appointmentNote?:string,
    appointmentType?:AppointmentTypeAndPlace,
    appointmentTypes?:Array<AppointmentTypeAndPlace>
    updatedTokenPromise?:Promise<string>;
    rememberMe?:boolean;
    placeId?:string,
}

interface Props {
    groupId:string,
    healthcareParty:HealthcareParty,
    user:User,
    placeId:string,
    appointmentTypes:Array<AppointmentTypeAndPlace>
}

initScreenList(APPOINTMENT_FLOW_SCREENS);

function AppointmentFlow ( { groupId, healthcareParty, user, appointmentTypes, placeId } : Props) {
     const [state, setState ] = useState <FlowState> ({
         groupId,
         healthcareParty,
         user,
         appointmentTypes,
         placeId,
         isNewPatient:false
     });

     useEffect(()=> {
        (async() => {
            setState(state => ({...state, screenName:gotoFirstValid(state)}));
            const existingPatient:Patient | undefined = getPatient();
            const existingPatientToken = findPatientToken(existingPatient?.tokensByGroup, groupId);

            const  existingPatientWithNewToken:Patient | undefined =
                existingPatient && existingPatientToken ? await renewPatientToken(existingPatient, groupId):undefined;

            setState(state => ({
                ...state,
                patient: existingPatientWithNewToken || existingPatient,
                rememberMe:!!existingPatient}));
            if (existingPatientWithNewToken) {
                    savePatient(existingPatientWithNewToken);
                }
                }
            )();
     },[]);

     useEffect(() => {
        const top = getScrollPositionForScreen(state.screenName as ScreenName);
        if (top !== undefined) window.scrollTo({top,behavior:"smooth"})
     },[state.screenName]);

    const getHeader =  () => (<AppointmentFlowHeader
        healthcareParty={state.healthcareParty!}
        appointmentTypes={state.appointmentTypes!}
        placeId={state.placeId!}
        cancelFlow = {cancelFlow}
    />);

   const submitScreen = (properties = {}) => {
       const screenName:ScreenName = gotoNextValid({...state, ...properties}) as ScreenName;
       setState({
           ...state,
           ...properties,
           screenName
       });
   }
    const cancelFlow = ()  => {
        trackGoal(FathomEvent.FLOW_CLOSE, 1);
        const resetState:FlowState = {
            ...state,
            appointmentType:undefined,
            timeslot:undefined,
        };
         setState({...resetState, screenName:gotoFirstValid(resetState) });
    }

    const gotoScreen = (screenName:ScreenName, stateChanges: {}) => {
       setState({
           ...state,
           ...stateChanges,
            screenName:gotoScreenName(screenName)}
           )
   }
    switch (state.screenName) {
        case ScreenName.PLACE_CHOICE:
            return (
                <PlaceChoice
                    flowState = {state}
                    onSubmit={(param) => submitScreen(param)}/>
                );
       case ScreenName.NEW_PATIENT_QUESTION:
           return (<>
                   {getHeader()}
                   <NewPatientQuestion
                    healthCarePartyName={formatHealthcarePartyName(state.healthcareParty as HealthcareParty)}
                    onSubmit={(param:{isNewPatient:boolean}) => submitScreen(param)}/>
               </>
               );

           case ScreenName.NEW_PATIENT_REJECTION:
           return (<>
                   {getHeader()}
               <NewPatientRejection
                   healthCarePartyName={formatHealthcarePartyName(state.healthcareParty as HealthcareParty)}
                   healthCarePartyPhoneNumber={selectPhoneNumberFromHealthcareParty(state.healthcareParty!)}/>
               </>
           );

           case ScreenName.CALENDAR_ITEM_TYPE_CHOICE:
           return (<>
               {getHeader()}
               <CalendarItemTypeChoice
                    flowState = {state}   
                    onSubmit={(param) => {
                        trackGoal(FathomEvent.FLOW_TYPE_SELECTED, 1);
                        submitScreen(param)}}/>
               </>);

       case ScreenName.TIMESLOT_CHOICE:
           return (<>
               {getHeader()}
                <TimeslotChoice
                   flowState = {state}
                   gotoScreen={(screenName, newState) => gotoScreen(screenName, newState)}
                   onSubmit={(param:{timeslot:number}) => {
                    trackGoal(FathomEvent.FLOW_SLOT_SELECTED, 1);
                    submitScreen(param)
                }}/>
               </>);
           case ScreenName.IDENTIFICATION :
           return (<>
                    {getHeader()}
                   <Identification
                   flowState={state}
                   gotoScreen={(screenName, newState) => gotoScreen(screenName, newState)}
                   onSubmit={(param) => {
                    trackGoal(FathomEvent.FLOW_FORM_SENT, 1);
                    submitScreen(param)
                }}/>
               </>);

       case ScreenName.VALIDATION:
           return (<>
               {getHeader()}
               <Validation
               gotoScreen={(screenName, newState) => gotoScreen(screenName, newState)}
                   flowState={state} onSubmit={(param) => {
                    trackGoal(FathomEvent.FLOW_CONFIRMATION_CODE_SENT, 1);
                    submitScreen(param)}
                    } />
           </>);

       case ScreenName.SAVE_APPOINTMENT:
           return (<>
               {getHeader()}
               <SaveAppointment flowState={state} />
           </>)

           default :
           const error: string = `Error: screen ${state.screenName} not found`;
           return (<div>{error}</div>);
   }
}

export default AppointmentFlow;