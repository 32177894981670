import {useParams} from "react-router";
import {
  getCalendarItem,
  getUserAndHealthcareParty,
  modifyCalendarItemRaw,
  sendCancellationConfirmationEmail,
} from "../services/apiService";
import { useEffect, useState } from "react";
import { CalendarItem, UserAndHealthcareParty } from "@icure/api";
import { Patient } from "../components/appointment-flow-screens/service/types";
import {
  formatAddress,
  formatHealthcarePartyName,
  getAgendaConfiguration,
} from "../services/utils";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";
import { API_TIME_FORMAT } from "../services/constants";

interface UrlParams {
  groupId: string;
  healthcarePartyId: string;
  calendarItemId: string;
  login: string;
  token: string;
}

export const CalendarItemPage = () => {
  const { t, i18n } = useTranslation();
  const { groupId, healthcarePartyId, calendarItemId, login, token } =
    useParams() as unknown as UrlParams;
  const [calendarItem, setCalendarItem] = useState<CalendarItem | undefined>(
    undefined
  );
  const [healthcareParty, selectHealthcareParty] = useState<
    CalendarItem | undefined
  >(undefined);
  const [canceled, setCanceled] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>(undefined);
  const patient: Patient = {
    userId: login,
    tokensByGroup: [{ groupId, token }],
    visitedHealthcarePartyIds: [],
  };

  useEffect(() => {
    (async () => {
      if (!calendarItem && !error) {
        try {
          const [calendarItem, { healthcareParty }]: [
            CalendarItem,
            UserAndHealthcareParty
          ] = await Promise.all([
            await getCalendarItem(calendarItemId, patient, groupId),
            getUserAndHealthcareParty(groupId, healthcarePartyId),
          ]);
          const config = getAgendaConfiguration(healthcareParty!);
          i18n.changeLanguage(config.interfaceDefaultLanguage);
          dayjs.locale(config.interfaceDefaultLanguage);

          setCalendarItem(calendarItem);
          selectHealthcareParty(healthcareParty);
        } catch (error) {
          setError(error as Error);
          throw error;
        }
      }
    })();
  });
  useEffect(() => {
    setCanceled(!!calendarItem?.cancellationTimestamp);
  }, [calendarItem]);

  const cancelItem = async (): Promise<void> => {
    const CANCELED_BY_PATIENT = "CANCELED_BY_PATIENT";
    const calendarItem = await getCalendarItem(
      calendarItemId,
      patient,
      groupId
    );
    await modifyCalendarItemRaw(
      {
        ...calendarItem,
        cancellationTimestamp: new Date().valueOf(),
        meetingTags: [
          ...calendarItem.meetingTags!,
          {
            code: CANCELED_BY_PATIENT,
            date: Number(dayjs().format(API_TIME_FORMAT)),
            userId: login,
          },
        ],
      },
      patient,
      groupId
    );

    sendCancellationConfirmationEmail(
      { patient, healthcareParty, groupId, timeslot: calendarItem!.startTime },
      calendarItem?.id!
    );
    setCalendarItem(
      await await getCalendarItem(calendarItemId, patient, groupId)
    );
  };

  if (healthcareParty && calendarItem) {
    return (
      <div className="main-container wrap">
        {!canceled ? (
          <>
            <h1>{t("FLOW.CANCEL_APPOINTMENT.HEADER")}</h1>
            <p>{t("FLOW.CANCEL_APPOINTMENT.BODY")}</p>

            <div className="appointment">
              <div className="appointmentDate">
                <div className="month">
                  {dayjs(String(calendarItem?.startTime)).format("MMM")}
                </div>
                <div className="day">
                  {dayjs(String(calendarItem?.startTime)).format("DD")}
                </div>
              </div>
              <div className="details">
                <p className="main-subtitle">
                  {formatHealthcarePartyName(healthcareParty)}
                </p>
                <p className="medium-title">
                  {dayjs(
                    String(calendarItem?.startTime),
                    API_TIME_FORMAT
                  ).format("ddd DD/MM/YYYY HH:mm")}
                </p>
                <p>{formatAddress(calendarItem.address!)}</p>
              </div>

              <button className="primary destructive" onClick={cancelItem}>
                {t("BUTTONS.CANCEL")}
              </button>
            </div>
          </>
        ) : (
          <>
            <h1>{t("FLOW.CANCEL_APPOINTMENT.CONFIRMATION_HEADER")}</h1>
            <p>{t("FLOW.CANCEL_APPOINTMENT.CONFIRMATION_BODY")}</p>
            <a
              href={`/${groupId}/${healthcarePartyId}`}
              className="primary extra-button"
            >
              {t("BUTTONS.NEW_APPOINTMENT")}
            </a>
          </>
        )}
      </div>
    );
  }
  return <div />;
};

