import {IcureApi} from "../components/appointment-flow-screens/service/types";
import {
  Api,
  IccAccesslogApi,
  IccCalendarItemApi,
  IccContactApi,
  IccPatientApi,
  LoginCredentials,
} from "@icure/api";
import { ICURE_HOST } from "./constants";
import { AuthenticationProvider } from "@icure/api/icc-x-api/auth/AuthenticationProvider";
const IS_BASIC_AUTH = true;

let apiPromise: Promise<IcureApi> | undefined = undefined;
let currentCredentials: LoginCredentials | undefined;

export const getApi = async (credentials: LoginCredentials): Promise<IcureApi> => {
  const { username, password } = credentials;
  if (!username!.includes("@") && !username!.includes("/")) {
    console.warn("username should either be an email or be groupId/usrId");
  }
  const credentialsDoMatch: boolean =
    password === currentCredentials?.password &&
    username === currentCredentials?.username;
  if (apiPromise && credentialsDoMatch) {
    return apiPromise;
  }
  await killApi();
  apiPromise = Api(
    ICURE_HOST,
    username!,
    password!,
    undefined,
    undefined,
    false,
    true,
    undefined,
    undefined,
    { "X-Bypass-Session": "true" }
  ).then((xApi) => {
    const HOST: string = ICURE_HOST;
    const HEADERS: string = '{ "X-Bypass-Session": "true" }';
    const authProvider: AuthenticationProvider =
      xApi.authApi?.authenticationProvider;

    const nonDecryptedApis = {
      accesslogApi: new IccAccesslogApi(HOST, HEADERS, authProvider),
      calendarItemApi: new IccCalendarItemApi(HOST, HEADERS, authProvider),
      contactApi: new IccContactApi(HOST, HEADERS, authProvider),
      patientApi: new IccPatientApi(HOST, HEADERS, authProvider),
    };

    return { ...xApi, nonDecryptedApis };
  });

  //api.authApi.setHeaders([...api.authApi.headers,new XHR.Header('X-Bypass-Session', 'true')])

  currentCredentials = credentials;

  return apiPromise;
};


export const killApi = async ():Promise<void>=> {
    if (apiPromise) {
        if(!IS_BASIC_AUTH) {
            await (await apiPromise)!.authApi.logout();
        }
        apiPromise = undefined;
    }
};

