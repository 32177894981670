import {FlowState} from "../AppointmentFlow";
import {
    checkTimeSlotAvailability,
    saveCalendarItem,
    sendConfirmationEmail
} from "../../services/apiService";
import { API_TIME_FORMAT } from "../../services/constants";
import {useEffect, useState} from "react";
import {ERROR} from "./service/types";
import {CalendarItem} from "@icure/api";
import {useTranslation} from "react-i18next";
import {formatHealthcarePartyName, getErrorMessage, selectAppointmentTypesByPlaceId} from "../../services/utils";
import dayjs from "dayjs";
import {trackGoal} from "fathom-client";
import * as Sentry from "@sentry/browser";
import {FathomError, FathomEvent} from "../../constants/fathom.constants";
import Loading from "../common/Loading";

interface Props {

    flowState:FlowState
}

enum Status {
    init,
    loading,
    error,
    success
}

export const SaveAppointment = ({flowState, flowState:{groupId, user, healthcareParty, appointmentType, appointmentTypes, isNewPatient, timeslot, placeId}}:Props) =>  {
    const [status, setStatus] = useState<Status>(Status.init);
    const [error, setError] = useState<ERROR>();
    const [calendarItem, setCalendarItem] = useState<CalendarItem|undefined>();
    const {t} = useTranslation();
    appointmentType = appointmentType || selectAppointmentTypesByPlaceId(appointmentTypes!, placeId!)[0];

    const handleError =  (error:ERROR) => {
        setStatus(Status.error);
        setError(error);
    }
    useEffect(() => {
        if(status === Status.init) {
            setStatus (Status.loading);
            (async () => {
                if (await checkTimeSlotAvailability(
                    groupId!,
                    user?.id!,
                    healthcareParty?.id!,
                    appointmentType!,
                    isNewPatient!,
                    timeslot!)) {
                    try {
                        const calendarItem:CalendarItem = await saveCalendarItem({...flowState, appointmentType});
                        setCalendarItem(calendarItem);
                        setStatus(Status.success);
                        trackGoal(FathomEvent.FLOW_APPOINTMENT_SAVED, 1);
                    } catch (e) {
                        trackGoal(FathomError.SAVE_APPOINTMENT!, 1);
                        const standardisedError:ERROR  = Object.keys(ERROR).includes((e as Error).message)?(e as Error).message as ERROR:ERROR.SAVE_APPOINTMENT;
                        handleError(standardisedError);
                        Sentry.captureException(e);
                        throw (e);
                    }
                } else {
                    trackGoal(FathomError.SLOT_NOT_AVAILABLE!, 1);
                    handleError(ERROR.SLOT_NOT_AVAILABLE);
                    throw (ERROR.SLOT_NOT_AVAILABLE);
                }
            })();
        }
        });
    return (
    <div className="saving-appointment">
                {status ===  Status.loading ?
                    <div><Loading message={t("UI.SAVING")}/> </div>
                    :
                    status ===  Status.success ?
                        <div>
                            <p dangerouslySetInnerHTML={{__html:t("FLOW.SAVE_APPOINTMENT.CONFIRMATION",{
                                name:formatHealthcarePartyName(healthcareParty!),
                                day:dayjs(String(calendarItem?.startTime), API_TIME_FORMAT).format ('dddd DD/MM/YYYY'),
                                time:dayjs(String(calendarItem?.startTime), API_TIME_FORMAT).format ('HH:mm')})}}/>
                            <a href={`/${groupId}/${healthcareParty?.id}`} className="primary extra-button">{t('BUTTONS.OTHER_APPOINTMENT')}</a>
                        </div>
                        :
                        <div>
                            <p>{error?getErrorMessage(error!):''}</p>
                            <a href={`/${groupId}/${healthcareParty?.id}`} className="primary extra-button">{t('BUTTONS.OTHER_APPOINTMENT')}</a>
                            </div>
            }
        </div>
    )
   
};