import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

export default function FAQPage(){

    const {t, i18n} = useTranslation();
    const [params] = useSearchParams();


    const lang =  params.get("lang");

    return  (
        lang === "nl" ?<div className="main-container wrap privacy">
        <h1>Veelgestelde vragen</h1>
        <div style={{display:"flex", alignContent:"flex-start", justifyContent:"space-between"}}>
        <h2 className="main-subtitle">Bent u een patiënt?</h2>
        <a href="/faq" style={{textAlign:"right"}}>Version française</a>
        
        </div>
        
        <div className='address-block collapsed' style={{marginTop:"2rem", display:"block"}}>
            ⚠️ Deze online afspraakdienst stelt u in staat om afspraken te maken met elke zorgverlener die de Medispring-software gebruikt. 
            Het bedrijf Medispring beheert echter niet de agenda's van haar gebruikers en kan u niet helpen met het maken, annuleren of wijzigen van afspraken, noch met medische vragen van welke aard dan ook.<br/>
            <span style={{fontWeight:500}}>Voor alle andere vragen dan de onderstaande lijst, verzoeken wij u direct contact op te nemen met de betreffende professional.</span>
        </div>
    
        <ul className="unordered-list">
            
    <li className="list-item"><span className="flow-title">Hoe werkt het maken van afspraken via internet?</span><br/>
    <span style={{fontWeight:500}}>1. Vind de website van de professional</span><br/>
    U vindt meestal de gezondheidsprofessional die u zoekt door simpelweg zijn naam in een zoekmachine (Google, Bing, Yahoo...) in te typen.<br/> 
    <span style={{fontWeight:500}}>2. Kies een type en een tijdslot voor de afspraak</span><br/>
    Eenmaal op de agenda van de professional, klik op "Nieuwe afspraak", kies het gewenste type afspraak, een beschikbaar tijdslot en vul uw gegevens in.<br/>
    <span style={{fontWeight:500}}>3. Bevestig uw afspraakverzoek</span><br/>
    Nadat u uw gegevens hebt verzonden, moet u uw aanvraag bevestigen met een verificatiecode die per e-mail naar u is verzonden.</li>
    <li className="list-item"><span className="flow-title">Hoe annuleer ik een afspraak?</span><br/>
    Om een afspraak te annuleren, klikt u op de knop "Afspraak annuleren" die u vindt in de bevestigingsmail die naar u is verzonden. <br/>
    Eenmaal op de annuleringspagina, bevestig uw verzoek door op "Annuleren" te klikken<br/>
    Let op dat sommige professionals zich het recht voorbehouden om een vergoeding in rekening te brengen bij late annulering.</li>
    <li className="list-item"><span className="flow-title">Hoe wijzig ik een afspraak?</span><br/>
    Om een afspraak te wijzigen, moet u eerst de bestaande afspraak annuleren, voordat u een nieuwe maakt.</li>
    
    <li className="list-item"><span className="flow-title">Ik heb een vraag over een bevestigings-/annulerings-/herinneringsbericht</span><br/>
    Als u denkt dat u zo'n bericht per ongeluk heeft ontvangen, als u een bericht heeft ontvangen over een afspraak waarvan u denkt dat u die niet heeft gemaakt, of die u dacht al geannuleerd te hebben, neem dan direct contact op met de betreffende professional. 
    Medispring heeft geen zicht op de agenda's van professionals die onze oplossing gebruiken, en is daarom niet in staat om u in deze kwestie te helpen.
    
    </li>
    
    <li className="list-item"><span className="flow-title">Ik heb een vraag over mijn consult</span><br/>
    Voor alle vragen met betrekking tot uw consult, de voorbereiding of de opvolging ervan (aanvragen van recepten, certificaten...), neem rechtstreeks contact op met uw arts.</li>
    
    
         </ul>
    
         
    
                </div>
    

        :
        <div className="main-container wrap privacy">
            <h1>Questions fréquemment posées</h1>
            <div style={{display:"flex", alignContent:"flex-start", justifyContent:"space-between"}}>
            <h2 className="main-subtitle">Vous êtes un(e) patient(e)?</h2>
            <a href="?lang=nl" style={{textAlign:"right"}}>Nederlandse versie</a>
            
            </div>
            
            <div className='address-block collapsed' style={{marginTop:"2rem", display:"block"}}>
                ⚠️ Le présent service de prise de rendez-vous en ligne vous permet de prendre rendez-vous avec tout prestataire de soins utilisateur du logiciel Medispring. 
                La société Medispring ne gère cependant pas les agendas de ses utilisateurs, et ne pourra vous aider, ni pour la prise de rendez-vous, l'annulation ou la modification de ceux-ci, ni pour quelque question médicale que ce soit.<br/>
                <span style={{fontWeight:500}}>Pour toute question autre que celles de la liste ci-dessous, nous vous invitons à prendre contact directement avec le professionnel concerné.</span>
            </div>

            <ul className="unordered-list">
				
<li className="list-item"><span className="flow-title">Comment fonctionne la prise de rendez-vous via Internet?</span><br/>
<span style={{fontWeight:500}}>1. Retrouver le site du professionnel</span><br/>
Vous retrouverez généralement le professionnel de santé recherché en tapant simplement son nom dans un moteur de recherche (Google, Bing, Yahoo...).<br/> 
<span style={{fontWeight:500}}>2. Choisir un type et un créneau de rendez-vous</span><br/>
Une fois sur l'agenda du professionnel, cliquez sur "Nouveau rendez-vous", choississez le type de rendez-vous souhaité, un créneau horaire disponible, et remplissez vos coordonnées.<br/>
<span style={{fontWeight:500}}>3. Valider votre demande de rendez-vous</span><br/>
Une fois vos coordonnées envoyées, il vous restera à confirmer votre demande au moyen d'un code de vérification qui vous aura été envoyé par email.</li>
<li className="list-item"><span className="flow-title">Comment annuler un rendez-vous?</span><br/>
Pour annuler un rendez-vous, cliquez sur le bouton "Annuler le rendez-vous" que vous trouverez dans l'e-mail de confirmation qui vous aura été envoyé. <br/>
Une fois sur la page d'annulation, confirmez votre demande en cliquant sur "Annuler"<br/>
Attention qu'en cas d'annulation tardive, certains professionnels se réservent le droit de facturer une compensation.</li>
<li className="list-item"><span className="flow-title">Comment modifier un rendez-vous?</span><br/>
Pour modifier un rendez-vous, il vous faut d'abord annuler le rendez-vous existant, avant d'en fixer un nouveau.</li>

<li className="list-item"><span className="flow-title">J'ai une question par rapport à un message de confirmation/annulation/rappel</span><br/>
Si vous pensez avoir reçu un tel message en erreur, si vous avez reçu un message en rapport avec un rendez-vous que vous ne pensez pas avoir pris, ou que vous pensiez avoir déjà annulé, nous vous invitons à prendre contact directement avec le professionnel concerné. 
Medispring n'a pas de vue sur les agendas des professionnels utilisant notre solution, et n'est dès lors pas en mesure de vous aider à ce sujet.

</li>

<li className="list-item"><span className="flow-title">J'ai une question par rapport à ma consultation</span><br/>
Pour toute question relative à votre consultation, à la préparation ou au suivi de celle-ci (demande d'ordonnance, de certificats...), veuillez prendre contact directement avec votre médecin.</li>


     </ul>

     

            </div>
    )
}