import React, {useEffect, useState} from 'react';
import { useTranslation } from "react-i18next";
import { UrlParams } from './HealthcarePartyPage';
import { useParams } from 'react-router-dom';
import { HealthcareParty, UserAndHealthcareParty } from '@icure/api';
import { getUserAndHealthcareParties } from '../services/apiService';
import { AgendaConfiguration, formatHealthcarePartyName, getAgendaConfiguration } from '../services/utils';
import ErrorScreen from '../components/ErrorScreen';


export default function PracticePage(){
    
    const {t, i18n} = useTranslation();
    const {groupId }:UrlParams = useParams() as unknown as UrlParams;

    const [list,setList] = useState<UserAndHealthcareParty[]>()
    const [admin,setAdmin] = useState<HealthcareParty>();
    const [doctors,setDoctors] = useState<Map<string,HealthcareParty[]>>();

    const [error,setError] = useState<string>();

    useEffect(() => {
        (async  () => {
            if (groupId) {
                try {
                    const list= await getUserAndHealthcareParties(groupId);


                    const hcps = list.filter(uh=>uh.healthcareParty?.speciality != "ADMINISTRATION");
                    const admin = list.find(uh=>uh.healthcareParty?.speciality === "ADMINISTRATION");


                    if (hcps){
                        setDoctors(groupBySpeciality(hcps.map(uh=>uh.healthcareParty!)))
                        setList(list);
                    } 

                    if(admin){
                        setAdmin(admin.healthcareParty)
                    }  else {
                        setError(t("UI.ERROR.HCPS_IN_GROUP"));
                    }
                } catch(error:any){
                    setError(error.message)
                }
            }
        })();
    }, [groupId]);

    function getInformationText(admin){
        const config = getAgendaConfiguration(admin);
        const informationText = config[i18n.language == "nl-BE" ? "nl" : "fr"] as string;
        if(informationText){
            return(
                 <div dangerouslySetInnerHTML={{ __html: informationText}}></div>
              
            );
        }
        return;
    }

    function groupBySpeciality(healthcareParties: HealthcareParty[]): Map<string, HealthcareParty[]> {
        return healthcareParties.reduce((acc, healthcareParty) => {
          const speciality = healthcareParty.speciality!;
      
          if (!acc.has(speciality)) {
            acc.set(speciality, []);
          }
      
          acc.get(speciality)!.push(healthcareParty);
          return acc;
        }, new Map<string, HealthcareParty[]>());
      }

    
    if (error) {
        return (<ErrorScreen message={error} links={(list ?? []).map(uh => {return {label:formatHealthcarePartyName(uh.healthcareParty!),url:`/${groupId}/${uh.healthcareParty?.id}`}})}/>)
    }


    return (<div className="main-container wrap">
        <h1>{admin && formatHealthcarePartyName(admin)}</h1>
        {
       admin && getInformationText(admin)

       
    }
    <div  style={{display:"grid", gridTemplateColumns:"repeat(auto-fit,minmax(320px,1fr))"}}>
    {doctors && [...doctors.entries()].sort((a, b) => a[0].localeCompare(b[0])).map(([speciality, parties]) => (
        <div key={speciality}>
          <h3>{ t(`SPECIALITIES.${speciality}`)}</h3>
          <ul className="links">
            {parties.map(party => (
              <li key={party.id}><a href={`/${groupId}/${party.id}`}>{formatHealthcarePartyName(party)}</a></li>
            ))}
          </ul>
        </div>
      ))}
        </div>
    </div>);
}