import phone from "phone";
import {Validation} from "../Identification";
import {InputName} from "./types";

export interface GroupedTimeslot {
        day:string;
        timeslots:number[];
}
const getYYYMMDDFromTime  = (time:number):string =>  String(time).substring(0,8);

export const attemptToNormalizePhoneNumber =  (phoneNumber:string):string => {
    const {phoneNumber:normalizedPhoneNumber} =  phone (phoneNumber, {country:'BE'});
    return normalizedPhoneNumber || phoneNumber;
};

export const getGroupedTimeslots = (timeSlots:number[]):GroupedTimeslot[] => {
    return timeSlots.sort().reduce((groupedTs:GroupedTimeslot[], timeSlot) => {
    const slotDay:string =  getYYYMMDDFromTime(timeSlot);
    let dayGroup:GroupedTimeslot | undefined = groupedTs.find (({day}) => day === slotDay);
    if (!dayGroup) {
        dayGroup = {
            day:slotDay,
            timeslots:[]
        }
        groupedTs = [...groupedTs, dayGroup]
    }
    dayGroup.timeslots = [...dayGroup.timeslots, timeSlot]
    return groupedTs;
},[]);
}

export const getInvalidFields = (validation:Validation):InputName[] =>
    Object.entries(validation).filter (([key, result]) => !result)
        .map(([key])=> key as InputName);