import { useTranslation } from "react-i18next";
import {ClassNames} from "./service/types";
import { FlowState } from "../AppointmentFlow";
import {selectAppointmentTypesByPlaceId} from "../../services/utils";

interface Props  {
    flowState:FlowState,
    onSubmit:(flowState:FlowState) => void
}

const CalendarItemTypeChoice = function  ({flowState:{appointmentTypes, isNewPatient, placeId}, onSubmit}:Props){
    const {t} = useTranslation();

    appointmentTypes =
        selectAppointmentTypesByPlaceId(appointmentTypes!, placeId!, true)
        .filter(type => !isNewPatient || type.acceptsNewPatients);
    return (
    <ol className="ordered-list">
    <li className="list-item transition-animation">
        <h3 className ="screen-title">{t('FLOW.CALENDAR_ITEM_TYPE_CHOICE.TITLE')}</h3>
    <ul className={ClassNames.flowContent}>
        {appointmentTypes
        .map((appointmentType, index) => {
            return <li key={index}><button  className="button primary" onClick={() =>onSubmit({appointmentType})}>{appointmentType.name}</button></li>
        })}
    </ul>
        </li>
    </ol>
    );
}

export default CalendarItemTypeChoice;
