import { Telecom, Place } from "@icure/api";

//@ts-ignore
import { ReactComponent as LandlineIcon} from "../assets/img/landline.svg"
//@ts-ignore
import { ReactComponent as EmailIcon} from "../assets/img/email.svg"
//@ts-ignore
import { ReactComponent as MobileIcon} from "../assets/img/mobile.svg"
//@ts-ignore
import { ReactComponent as DirectionsIcon} from "../assets/img/compass-direction.svg"

import { useTranslation } from "react-i18next";


export function getTelecomIconFromType(type?:Telecom.TelecomTypeEnum){
  switch(type){
    case Telecom.TelecomTypeEnum.Email:
      return <EmailIcon/>
    case Telecom.TelecomTypeEnum.Phone:
      return <LandlineIcon/>
    default:
        return <MobileIcon/>         
  }
}

const SUPPORTED_TYPES = [Telecom.TelecomTypeEnum.Email,Telecom.TelecomTypeEnum.Mobile,Telecom.TelecomTypeEnum.Phone]

export function supportedTelecomsOnly(telecom:Telecom){
  return SUPPORTED_TYPES.includes(telecom?.telecomType!)
}


interface Props {
  place:Place,
  onSelect?:Function,
  isExpanded:boolean,
  acceptsAppointments:boolean,
  acceptsChange:boolean,
  onChange?:Function
}

export default function AddressBlock(
  {place, onSelect, isExpanded, acceptsAppointments, acceptsChange, onChange}:Props){
   const address = place.address;
  const {t} = useTranslation();
    return <div  className={isExpanded ? "address-block" : "address-block collapsed"}>
      <div className="contact-details">
        <address>
            <DirectionsIcon/>
            <p>

              {address?.descr && <><strong>{address?.descr}</strong><br/></> /* using address.descr instead of place.name, because AppointmentTypeAndPlaceDTO doesn’t contain a PlaceDTO, but a placeId and an AddressDTO :( */}
              {address?.street} {address?.houseNumber}<br/>
              <span className='postal-code'>{address?.postalCode}</span>&nbsp;
              <span className={'locality'}>{address?.city}</span><br/>
              <a target="_blank" href={'https://www.google.com/maps/dir/?api=1&?origin=Current+location&destination='+encodeURIComponent(`${address?.street} ${address?.houseNumber},${address?.postalCode} ${address?.city}, ${address?.country}`)}>{t("BUTTONS.VIEW_ITINERARY")}</a>
            </p>

           
        </address>
        { isExpanded && 
          <><ul className="telecoms">
            {address?.telecoms?.filter(supportedTelecomsOnly)
            .map((t:Telecom,i:number) => <li key={i}>{getTelecomIconFromType(t.telecomType)} <a  href={`tel:`}>{t.telecomNumber}</a></li>)}
          </ul>

          {acceptsAppointments ? 
            <button className="button primary" onClick={()=>onSelect && onSelect()}>{t('BUTTONS.START_APPOINTMENT')}</button>
            : 
            <p className="small-text">{t("FLOW.TIMESLOT_CHOICE.NO_TIMESLOTS")}</p>}
            </>
            }

          {!isExpanded && acceptsChange &&
             <div className="change"> <button className="flow-screen-link" onClick={()=>onChange && onChange()}>
                {t("BUTTONS.CHANGE")}
              </button></div>
            }
      </div>        
              
       <iframe className={isExpanded ? "map" : "map collapsed"} id="map"
            title = "Map"
            width="100%"
            height="100%"
            style={{border:0}}
            loading="lazy"
            allowFullScreen
            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyB_XCU_o291M3tGlBNcR01PwPQvEcnC0vE&q=${address?.street} ${address?.houseNumber},${address?.postalCode} ${address?.city}, ${address?.country}`}>
          </iframe>
    </div>
}