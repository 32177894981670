export const API_TIME_FORMAT:string = "YYYYMMDDHHmmss";
export const PROCESS_ID:{[lang:string]:string}= {
    "fr":"4ae7f5ef-33a5-4b28-91ee-622742a8351f",
    "nl-BE":"7a312240-2e26-47b0-8094-92fe40a8a739",
    "de":"c77ceb5d-41cd-446a-ae52-7830295ce7e9"
}
export const ICURE_HOST:string = "https://kraken.icure.cloud/rest/v1";
export const MESSAGE_HOST:string = "https://msg-gw.icure.cloud";
export const FROM_EMAIL:string = "no-reply@medispring.be";
export const TEMPLATES_PATH = 'https://emails.medispring.be/{{ns}}/{{lng}}.json'
export const SPEC_ID = "ms" // ms

//const PROCESS_ID_DEV = "df6eed57fb67481aaa5e058d1ad2b9e9";
//const MOCK_RECAPTCHA = "a58afe0e-02dc-431b-8155-0351140099e4";
//const MESSAGE_HOST ="https://octi9gw.taktik.to";
//const ICURE_HOST = "https://kraken.icure.dev/rest/v1"
//const ICURE_HOST = "https://localkraken.taktik.to/rest/v1"
