
import {
    AppointmentTypeAndPlace,
    Place,
    CalendarItem,
    HealthcareParty,
    Address,
    UserAndHealthcareParty,
    CodeStub,
    retry,
    Telecom
} from "@icure/api";
import {ERROR, Patient, ScreenName, TokenByGroup} from "../components/appointment-flow-screens/service/types";
import dayjs from "dayjs";
import { API_TIME_FORMAT } from "./constants";
import {Patient as IcurePatient} from '@icure/api/';
import i18n from "i18next";
import {URL_PARAM_GROUP_ID, URL_PARAM_HCP_ID} from "../constants/main.constants";
import { ReactElement } from "react";
import { FROM_EMAIL, MESSAGE_HOST } from "./constants";
import { supportedTelecomsOnly } from "../components/AddressBlock";

export const formatAddress = (address:Address):string =>  address? `${address.street} ${address.houseNumber}, ${address.postalCode} ${address.city}`: ``;

export const formatTelecoms = (address:Address):string => {
    const list = address?.telecoms?.filter(supportedTelecomsOnly)
                                     .map((t:Telecom,i:number) => `<a href="${t.telecomType === Telecom.TelecomTypeEnum.Email ? "mailto" : "tel"}:${t.telecomNumber}">${t.telecomNumber}</a>`);

    return list?.length ? `<br/>${list.join("<br/>")}` : '';

}
export const formatPatientName  =
    ({firstName,lastName}:Patient | IcurePatient):string =>
        `${lastName?.toUpperCase()} ${firstName?.split(/[\s-]+/).filter(Boolean).map(name => `${name[0].toUpperCase()}${name.slice(1)}`).join(" ")}`;

export const formatHealthcarePartyName  = ({name,firstName,lastName, civility}:HealthcareParty):string => `${civility?civility:''} ${firstName && lastName?`${firstName} ${lastName}`:`${name}`}`;

export const selectAddressFromHealthcareParty= ({addresses}:HealthcareParty) => addresses?.find ((address:Address) => address.addressType === Address.AddressTypeEnum.Work) || addresses?.find (()=>true);

export const selectPhoneNumberFromHealthcareParty = (healthcareParty:HealthcareParty):string | undefined =>
    selectAddressFromHealthcareParty (healthcareParty)?.telecoms?.find(()=> true)?.telecomNumber;//TODO:add selection logic

export const invalidateToken = (tokensByGroup:TokenByGroup[], groupIdToDelete:string):TokenByGroup[] => tokensByGroup?.filter(({groupId}) => groupId !== groupIdToDelete);

export const findPatientToken = (tokensByGroup:TokenByGroup[] | undefined,groupIdToFind:string):string | undefined => tokensByGroup?.find(({groupId}) => groupIdToFind === groupId)?.token;

export const getNow = ():number => Number(dayjs().format (API_TIME_FORMAT));

export const getOneYearFrom = (startDate:number):number  => Number(dayjs(startDate).add(1, "year").format(API_TIME_FORMAT));

export const getMillisecondsBetweenNowAndDate = (date:string) => dayjs(date, API_TIME_FORMAT).diff(dayjs());



export const getErrorMessage = (id:ERROR):string => {
    const labelId:string = `ERRORS.${id}`;
    const msg:string = i18n.t(labelId);
    if (msg === labelId) {//Message not found
        return  i18n.t(`ERRORS.GENERIC`);
    }
    return msg;
}

const trimWhiteSpaceAndSpecialCharacters = (string:String) => {
    const nonAlphaNumericAtStartOrEnd:RegExp = /^[^A-Za-zÀ-ÿ0-9]+|[^A-Za-zÀ-ÿ0-9]+$/ig;
    return string.replace(nonAlphaNumericAtStartOrEnd, '');
}

export const trimPatientNamesFromWhiteSpaceAndSpecialCharacters = (patient:Patient):Patient => { // trims spaces and special characters of patient names
    return  {
        ...patient,
        firstName:trimWhiteSpaceAndSpecialCharacters(patient.firstName!),
        lastName:trimWhiteSpaceAndSpecialCharacters(patient.lastName!)
    }
}


export interface AgendaConfiguration {
    sendPatientEmailUponAppointmentCreation?: boolean;
    sendPatientEmailUponAppointmentModification?: boolean;
    sendPatientEmailUponAppointmentDeletion?: boolean;
    sendPatientEmailReminder?: boolean;
    sendPatientEmailReminderDelayBeforeAppointment?: number;
    allowPatientToIncludeNoteUponAppointmentCreation?: boolean;
    fr?:string;
    nl?:string;
    de?:string;
    interfaceDefaultLanguage?:"fr" | "nl" | "de";
  }
export const getAgendaConfiguration = (hcp:HealthcareParty) : AgendaConfiguration=> { 
    return {
        fr:hcp.descr?.fr,
        nl:hcp.descr?.nl,
        de:hcp.descr?.de,
        interfaceDefaultLanguage:(hcp.descr?.interfaceDefaultLanguage === "nl" ? "nl-BE" : hcp.descr?.interfaceDefaultLanguage ?? "fr" ) as "fr" | "nl" | "de",
      sendPatientEmailUponAppointmentCreation:
       hcp.descr?.sendPatientEmailUponAppointmentCreation === 'true',
        sendPatientEmailUponAppointmentModification:
       hcp.descr?.sendPatientEmailUponAppointmentModification === 'true',
      sendPatientEmailUponAppointmentDeletion:
        hcp.descr?.sendPatientEmailUponAppointmentDeletion === 'true',
      sendPatientEmailReminder: hcp.descr?.sendPatientEmailReminder === 'true',
      sendPatientEmailReminderDelayBeforeAppointment:
        Number(hcp.descr?.sendPatientEmailReminderDelayBeforeAppointment) ||
        24,
      allowPatientToIncludeNoteUponAppointmentCreation:
        hcp.descr?.allowPatientToIncludeNoteUponAppointmentCreation === 'true',
    };
  }

export const selectAppointmentTypesByPlaceId = (appointmentTypes: AppointmentTypeAndPlace[], selectedPlaceId: string, removeDuplicateTypes: boolean = false) =>
    appointmentTypes
        .sort((a, b) => Number(b.acceptsNewPatients) - Number(a.acceptsNewPatients))
        .filter(({placeId}) => !placeId || placeId === selectedPlaceId)
        .filter((appointmentType,index,array) =>
            !removeDuplicateTypes || array.findIndex((el) => el.calendarItemTypeId === appointmentType.calendarItemTypeId) === index)
        .sort((a, b) => a.name?.localeCompare(b.name!) || 0);


// This is temporary, until we figure out how to display addresses when in booking flow
export const locationFromSelectedPlace = (place:Place | undefined) => {
    if (place){
        return place.name ? " / " + place.name : "";
    }
    return "";
}

export const DEFAULT_PLACE_ID = "defaultPlaceId";

export const getUniquePlacesFromAppointmentTypesAndHCP = (appointmentTypes:Array<AppointmentTypeAndPlace>, hcp?:HealthcareParty) => {

    // if there are no appointment locations, use the HCP address as fallback
    if (!appointmentTypes.some(type => type.placeId && type.address)){
        return [new Place(
            {
            id:DEFAULT_PLACE_ID,
            address:selectAddressFromHealthcareParty(hcp!)}
            )]
     }

   return appointmentTypes
                .map(type => type.placeId && type.address ? getPlaceFromAppointmentType(type) : {id:DEFAULT_PLACE_ID, address:selectAddressFromHealthcareParty(hcp!)})
                .filter(uniquePlacesOnly)
   
}

const uniquePlacesOnly = (place:Place, index:number, self:Array<Place>) => self.findIndex(p => p.id === place.id) === index

const getPlaceFromAppointmentType  = (type:AppointmentTypeAndPlace) => {
   return new Place({id:type.placeId,address:type.address,name:type.address?.descr})
}

export const getLinkToHCP = function(groupId:string,hcp:UserAndHealthcareParty) :ReactElement{
    return <a href={`${window.location.pathname}?${URL_PARAM_GROUP_ID}=${groupId}&${URL_PARAM_HCP_ID}=${hcp.user?.healthcarePartyId}`}>
        {hcp.user?.name}    
    </a>
}

export const getHost = ():string  => {
    return `${window.location.protocol}//${window.location.hostname}${window.location.port?`:${window.location.port}`:''}/`;
}

export const buildCalendarItem = (
    userId:string,
    hcp:HealthcareParty,
    agendaId:string,
    {phoneNumber, lastName, firstName}:Patient,
    {calendarItemTypeId, duration, address}:AppointmentTypeAndPlace,
    timeslot:number,
    note?:string):CalendarItem => {
    const details = `${i18n.t("FLOW.SAVE_APPOINTMENT.FROM_MEDISPRING")} ${formatPatientName({
        lastName,
        firstName}
    )}${note ? `\n ${note}` : ""}`;
    return {
        author:userId,
        responsible:hcp.id!,//TODO: check if this could be patientId: does it work in MS?
        agendaId,
        title: `${phoneNumber} - ${details}`,
        calendarItemTypeId,
        duration,
        address : address ?? selectAddressFromHealthcareParty(hcp),
        homeVisit:false,
        phoneNumber: phoneNumber,
        details:details,
        startTime:Number (timeslot),
        endTime:Number(dayjs(String(timeslot), API_TIME_FORMAT).add(duration!, 'minutes').format(API_TIME_FORMAT)),
        tags:[PATIENT_APP_TAG],
    }
};

export const getScrollPositionForScreen = (screenName?:ScreenName)=>{
    
    const confirmationButton = document.querySelector("#confirmation-button") as HTMLButtonElement;
   const buttonHeight = confirmationButton ? parseInt(getComputedStyle(confirmationButton).getPropertyValue("height")) + parseInt(getComputedStyle(confirmationButton).getPropertyValue("--marginL")) : 0;

    const flowHeader =  document.querySelector(".flow-header") as HTMLDivElement;
    const flow =  document.querySelector(".flow") as HTMLDivElement;
   // const identification =  document.querySelector(".identification-form") as HTMLDivElement;
   
    switch(screenName){
        case ScreenName.CALENDAR_ITEM_TYPE_CHOICE:
            // show HCP name / Nouveau rdv
           return flowHeader!.offsetTop -  parseInt(getComputedStyle(flowHeader).getPropertyValue("--marginM"));
        case ScreenName.NEW_PATIENT_QUESTION:
            // show HCP name / Nouveau rdv
            return flowHeader!.offsetTop;
            
        case ScreenName.TIMESLOT_CHOICE:
            // top of .flow (1-2-3)
            return flow!.offsetTop -  parseInt(getComputedStyle(flow).getPropertyValue("--marginM"));
            
        case ScreenName.VALIDATION:
            // confirmation button should be at bottom of screen
            return confirmationButton!.offsetTop - window.innerHeight + buttonHeight!
         case ScreenName.IDENTIFICATION:
            // put the form on top
            // commenting this for now, as it's pretty tricky because of the keyboard on mobile
           // topPosition = identification!.offsetTop-  parseInt(getComputedStyle(flow).getPropertyValue("--marginM"));
           break;
        case ScreenName.SAVE_APPOINTMENT:
            // scroll back to top
            return 0
    }
}

const getHeader = (login: string, password: string): { Authorization: string } => ({
    Authorization: `Basic ${btoa(`${login}:${password}`)}`,
});


export const sendEmail = async (to:string,
                                token:string,
                                subject:string,
                                body:string,
                                time?:number,
                                eventId?:string)=>{
    const endpoint = time ? `${MESSAGE_HOST}/ms/queue/email/to/${to!}/on/${time}?eventId=${eventId}` : `${MESSAGE_HOST}/ms/email/to/${to!}`  
    return await retry(async () => {
            const response:Response = await fetch(endpoint, {
            method: 'POST',
            headers: {...getHeader(to!, token), 'Content-Type': 'application/json'},
            body: JSON.stringify({
                subject: subject,
                from: FROM_EMAIL,
                html: body
            })
        })
        if (!response.ok) {
            throw (new Error(response.statusText));
        } else {
            // The 'send later" endpoint will return a JSON object with details
            // But the "send now" endpoint returns a simple "ok"
            // Therefore we need to check the content type and return the response accordingly
            var contentType = response.headers.get("content-type");
            if(contentType && contentType.indexOf("application/json") !== -1) {
                return await response.json();
            }
            return await response.text();
            
        }
    }, 5, 500);
}

export const cancelReminder = async (id:string,
                                email:string,
                                token:string
                               )=>{
    const {ok,statusText}:Response = await fetch(`${MESSAGE_HOST}/ms/event/${id}`, {
        method: 'DELETE',
        headers: {...getHeader(email, token), 'Content-Type': 'application/json'}
    })
    if (!ok) {
        throw (new Error(statusText));
    } 
}

export const PATIENT_APP_TAG:CodeStub =  {
    id: "ms-createdBy",
    code: "ms-patient-calendar"
}